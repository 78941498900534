export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
export const PHONE_REGEX = /^[0-9\s()*#+-]+$/;
export const WEBSITE_REGEX = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
export const REQUIRED_MESSAGE = 'Field is required';
export const INVALID_EMAIL = 'Enter a valid email address';
export const INVALID_PHONE = 'Enter a valid phone number';
export const INVALID_WEBSITE = 'Enter a valid website address';
export const VALID_FILE_FORMATS = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
    "application/pdf"
];