import React from 'react';
import * as styles from './alert-popup.module.scss';

type AlertPopupProps = {
    message: string,
    closePopup: () => void
}

const AlertPopup: React.FC<AlertPopupProps> = ({ message, closePopup }) => {
    return (
        <div className={styles.alertPopup}>
            <div className={styles.alertPopupInner}>
                <div className={styles.alertPopupInnerClose} onClick={closePopup} />
                <div className={styles.alertPopupInnerMessage}>
                    {message}
                </div>
            </div>
        </div>
    )
}

export default AlertPopup