import classNames from 'classnames';
import React from 'react';
import * as styles from './textarea.module.scss';

type TextareaProps = {
    name: string,
    label: string,
    placeholder: string,
    cols?: number,
    rows?: number,
    className?: string,
    required?: boolean
    value?: string
    onChange?: any,
    error?: string
}

const Textarea: React.FC<TextareaProps> = ({
    name,
    label,
    placeholder,
    cols = 30,
    rows = 4,
    className,
    required,
    value, 
    onChange,
    error
}) => {
    return (
        <div className={classNames(className, styles.textarea)}>
            <label className={styles.textareaLabel} htmlFor={name}>{label}</label>
            <textarea
                className={styles.textareaField}
                id={name}
                name={name}
                cols={cols}
                rows={rows}
                placeholder={placeholder}
                required={!!required}
                value={value}
                onChange={onChange}
            />
            <span className={styles.textareaError}>{error}</span>
        </div>
    );
};

export default Textarea;