import classNames from 'classnames';
import React from 'react';
import * as styles from './button.module.scss';

type ButtonProps = {
    name: string,
    type?: "button" | "submit" | "reset",
    className?: string,
    onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({ name, type = 'button', className, onClick }) => {
    return (
        <button
            type={type}
            className={classNames(className, styles.button)}
            onClick={() => onClick && onClick()}
        >
            {name}
        </button>
    );
};

export default Button;