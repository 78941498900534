import React from 'react';
import SPINNER_ICON from '../../images/spinner.svg';
import * as styles from './spinner.module.scss';

const Spinner: React.FC = () => {
    return (
        <div className={styles.spinner}>
            <img src={SPINNER_ICON} alt="" className={styles.spinnerGif} />
        </div>
    )
}

export default Spinner;