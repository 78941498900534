import classNames from 'classnames';
import React from 'react';
import * as styles from './input.module.scss';

type InputProps = {
    label: string,
    name?: string,
    placeholder?: string,
    className?: string,
    required?: boolean,
    value?: string,
    onChange?: any,
    error?: string,
    readOnly?: boolean,
}

const Input: React.FC<InputProps> = ({
    label,
    name,
    placeholder = 'Type smth...',
    className,
    required,
    value, 
    onChange,
    error,
    readOnly,
}) => {
    return (
        <div className={classNames(className, styles.inputWrap)}>
            <label className={styles.inputWrapLabel} htmlFor={name}>{label}</label>
            <input 
                className={styles.inputWrapField} 
                id={name}
                name={name}
                type="text" 
                placeholder={placeholder} 
                required={!!required}
                value={value}
                onChange={onChange}
                readOnly={readOnly}
            />
            <span className={styles.inputWrapError}>{error}</span>
        </div>
    );
};

export default Input;